exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blogs-index-js": () => import("./../../../src/pages/blogs/index.js" /* webpackChunkName: "component---src-pages-blogs-index-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-sales-js": () => import("./../../../src/pages/contact-sales.js" /* webpackChunkName: "component---src-pages-contact-sales-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-fifth-js": () => import("./../../../src/pages/Fifth.js" /* webpackChunkName: "component---src-pages-fifth-js" */),
  "component---src-pages-first-js": () => import("./../../../src/pages/First.js" /* webpackChunkName: "component---src-pages-first-js" */),
  "component---src-pages-fourth-js": () => import("./../../../src/pages/Fourth.js" /* webpackChunkName: "component---src-pages-fourth-js" */),
  "component---src-pages-frequently-asked-questions-js": () => import("./../../../src/pages/frequently-asked-questions.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-js" */),
  "component---src-pages-get-started-js": () => import("./../../../src/pages/get-started.js" /* webpackChunkName: "component---src-pages-get-started-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-teams-js": () => import("./../../../src/pages/our-teams.js" /* webpackChunkName: "component---src-pages-our-teams-js" */),
  "component---src-pages-our-work-js": () => import("./../../../src/pages/our-work.js" /* webpackChunkName: "component---src-pages-our-work-js" */),
  "component---src-pages-our-works-js": () => import("./../../../src/pages/our-works.js" /* webpackChunkName: "component---src-pages-our-works-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-second-js": () => import("./../../../src/pages/Second.js" /* webpackChunkName: "component---src-pages-second-js" */),
  "component---src-pages-services-brand-design-js": () => import("./../../../src/pages/services/brand-design.js" /* webpackChunkName: "component---src-pages-services-brand-design-js" */),
  "component---src-pages-services-e-commerce-design-js": () => import("./../../../src/pages/services/e-commerce-design.js" /* webpackChunkName: "component---src-pages-services-e-commerce-design-js" */),
  "component---src-pages-services-social-media-marketing-js": () => import("./../../../src/pages/services/social-media-marketing.js" /* webpackChunkName: "component---src-pages-services-social-media-marketing-js" */),
  "component---src-pages-services-web-design-js": () => import("./../../../src/pages/services/web-design.js" /* webpackChunkName: "component---src-pages-services-web-design-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-third-js": () => import("./../../../src/pages/Third.js" /* webpackChunkName: "component---src-pages-third-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/single-post.js" /* webpackChunkName: "component---src-templates-single-post-js" */)
}

